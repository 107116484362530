// src/app.js

import './bootstrap';
import 'bootstrap/js/dist/dropdown';
import '../css/app.css';
import '../css/chat.css';
import '../css/global.css';
import '../css/fonts.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import axios from 'axios';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

document.addEventListener('DOMContentLoaded', () => {

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.withCredentials = true; 



    createInertiaApp({
        title: (title) => `${title} - ${appName}`,
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
        setup({ el, App, props, plugin }) {
            const app = createApp({ render: () => h(App, props) });

            app.use(plugin)
               .use(ZiggyVue);

            app.config.globalProperties.$axios = axios;

            return app.mount(el);
        },
        progress: false,
    });
});
